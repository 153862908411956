<template>
  <div class="fix-store-user">
    <h3>店铺所属账号信息</h3>

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :data="userList"
      border
      style="width:90%; max-width: 100%"
      :cell-style="csllStyle"
    >
      <el-table-column prop="USID" label="用户编号" width="240%">
      </el-table-column>
      <el-table-column prop="username" label="用户名称" width="200%">
      </el-table-column>
      <el-table-column prop="cardNo" label="会员卡编号" width="200%">
      </el-table-column>
      <el-table-column prop="adminState" label="当前状态" width="160%">
      </el-table-column>
      <el-table-column prop="verify" label="身份" width="160%">
      </el-table-column>
      <el-table-column prop="password" label="登陆密码" width="160%">
      </el-table-column>
      <el-table-column prop="db" label="开通时间" width="260%">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import secret from "@/utils/secret.js";
import axios from "axios";
export default {
  inject: ["reload"],
  props: ["storeKey"],
  data() {
    return {
      system_num: "",
      userList: [],
    };
  },
  created() {},
  mounted() {
    const userInfo = true;
    const userKey = this.storeKey;
    const storeKey = secret.Decrypt(localStorage.getItem("user_key"));
    axios
      .post(this.GLOBAL.api + "/store", { userInfo, storeKey, userKey })
      .then((res) => {
        res.data.val.forEach((ele, i) => {
            this.userList.push(ele);
            if(ele.adminState === '1'){
                this.userList[i].adminState = '正常'
            } else {
                this.userList[i].adminState = '冻结'
            }
            if(ele.verify === '1'){
                this.userList[i].verify = '超级管理员'
            } else {
                this.userList[i].verify = '普通管理员'
            }
        });
      });
  },
  
    csllStyle(row) {
      // // 监听tabel数据进行操作，如颜色警告
      console.log('row---',row);
      if (row.row.adminState === '冻结') {
        return "backgroundColor:rgb(167 41 41);color:#FFF";
      }
    },
};
</script>
<style scoped>
.fix-store-user {
    width: 90%;
    margin: 20px auto;
}
h3 {
    margin: 20px 0;
}
</style>