<template>
  <!-- 店铺列表
        除了超级终端外其他店铺使用时只显示自己店铺的一条信息
        超级终端可查看所有店铺的信息，可以进行编辑
   -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="各店铺账号管理"
    ></el-page-header>
    <el-form class="select-style">
      <el-form-item label="选择操作方式:">
        <el-select
          v-model="storename"
          filterable
          remote
          reserve-keyword
          placeholder="请输入搜索內容"
          :remote-method="remoteMethodsTorename"
          :loading="loading"
          style="width: 25%; margin-right: 2%"
        >
          <el-option
            v-for="item in storeOptions"
            :key="item.storeFlag"
            :label="`${item.title}`"
            :value="item.storeFlag"
          >
          </el-option>
        </el-select>
        <el-radio v-model="selectStyle" label="1">为店铺开通账号</el-radio>
        <el-radio v-model="selectStyle" label="2">查看店铺账号信息</el-radio>
        <el-button type="success" @click="selectButton">确定</el-button>
      </el-form-item>
    </el-form>
    <div class="line"></div>
    <createdUser :storeKey="storeKey" v-if="cut === 1"></createdUser>
    <fixUser :storeKey="storeKey" :usernumInfo="usernumInfo" v-if="cut === 2"></fixUser>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
import createdUser from "../../childComponents/store-craetes-user.vue";
import fixUser from "../../childComponents/store-fix-user.vue";
export default {
  inject: ["reload"],
  data: function () {
    return {
      cut: 0,
      selectStyle: "1",
      storename: "",
      loading: false,
      storeOptions: [],
      storeInfo: "",
      storeKey: "",
    };
  },
  mounted() {
    this.storeKey = secret.Decrypt(localStorage.getItem("user_key"));
  },
  components: {
    createdUser,
    fixUser,
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    remoteMethodsTorename(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          const title = query;
          this.storeOptions = axios
            .post(this.GLOBAL.api + "/store", { title })
            .then((item) => {
              if (item.data.length === 0) {
                this.$message({
                  type: "warning",
                  message: "没有搜索到该店铺",
                });
              }
              return (this.storeOptions = item.data);
            });
          this.loading = false;
        }, 200);
      } else {
        this.storeOptions = [];
      }
    },
    selectButton() {
      if (this.storeOptions.length === 0) {
        this.$message({
          type: "error",
          message: "请输入店铺名称",
        });
      } else {
        console.log(this.storeOptions[0]);
        this.cut = +this.selectStyle;
        // this.storeKey = this.storeOptions[0].storeFlag;
        this.storeKey = this.storename;
      }
      // if (this.selectStyle === "2") {
      //   const userInfo = true;
      //   const userKey = this.storeKey;
      //   const storeKey = secret.Decrypt(localStorage.getItem("user_key"));
      //   console.log("信息---", userKey);
      //   axios
      //     .post(this.GLOBAL.api + "/store", { userInfo, storeKey, userKey })
      //     .then((res) => {
      //       console.log("获取值---", res);
      //       this.usernumInfo = res.data.val;
      //     });
      // }
    },
  },
  watch: {
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
body {
  padding: 10px 20px;
}
.select-style {
  margin: 20px 0 10px 20px;
}
.line {
  border: dashed 2px #f3f3f3;
}
</style>